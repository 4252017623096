<template>
  <div>
    <div class="left-panel-bg"></div>

    <div class="left-panel">
      <div class="card">
        <div class="header">
          <img src="../../assets/card-header-bg.png" alt="" />
          <span class="title">管长分析</span>
        </div>
        <div class="body">
          <BaseInfo
            :sewageLength="sewageLength"
            :rainLength="rainLength"
            :sewageLengthList="sewageLengthList"
            :rainLengthList="rainLengthList"
          />
        </div>
      </div>

      <div style="padding-top: 2vh" />
      <div class="card">
        <div class="header">
          <img src="../../assets/card-header-bg.png" alt="" />
          <span class="title">管径分析</span>
        </div>
        <div class="body">
          <PipeDiameter
            :sewageDiameter="sewageDiameter"
            :rainDiameter="rainDiameter"
          />
        </div>
      </div>

      <div style="padding-top: 2vh" />
      <div class="card">
        <div class="header">
          <img src="../../assets/card-header-bg.png" alt="" />
          <span class="title">管网设施分析</span>
        </div>
        <div class="body">
          <WellInfo :drainList="drainList" :wellList="wellList" />
        </div>
      </div>
    </div>

    <Map :alertText="alertText" />

    <div class="right-panel">
      <div class="card">
        <div class="header">
          <img src="../../assets/card-header-bg.png" alt="" />
          <span class="title">降雨预报</span>
          <div class="extra" @click="$router.push('/weather')">
            <span style="font-size: 1.4vh">更多</span>
            <a-icon type="right" style="font-size: 1.4vh" />
          </div>
        </div>

        <div class="body">
          <Weather />
        </div>
      </div>
      <div style="padding-top: 2vh" />

      <div class="card">
        <div class="header">
          <img src="../../assets/card-header-bg.png" alt="" />
          <span class="title">管网系统设备</span>
        </div>
        <div class="body">
          <Status />
        </div>
      </div>

      <div class="card">
        <div class="header">
          <img src="../../assets/card-header-bg.png" alt="" />
          <span class="title">河道系统设备</span>
        </div>
        <div class="body">
          <StatusRiverVersion />
        </div>
      </div>

      <div style="padding-top: 2vh" />
      <div class="card">
        <div class="header">
          <img src="../../assets/card-header-bg.png" alt="" />
          <span class="title">最新报警</span>
          <div class="extra">
            <AlertList @select="select" />
          </div>
        </div>
        <div class="body">
          <AlertComponent />
        </div>
      </div>
    </div>

    <div class="right-panel-bg"></div>
  </div>
</template>

<script>
import BaseInfo from "./components/base-info.vue";
import PipeDiameter from "./components/pipe-diameter.vue";
import WellInfo from "./components/well-info.vue";

import Map from "./components/map";

import Weather from "./components/weather.vue";

import Status from "./components/status.vue";
import StatusRiverVersion from "./components/status-river-version.vue";
import AlertComponent from "./components/alert.vue";
import AlertList from "./components/alert-list.vue";

import request from "@/api/request";

export default {
  components: {
    BaseInfo,
    PipeDiameter,
    WellInfo,

    Status,
    StatusRiverVersion,
    AlertComponent,
    AlertList,
    Map,

    Weather,
  },

  data() {
    return {
      alertText: {},

      lengthList: [],

      // 管道长度
      sewageLength: 0,
      rainLength: 0,

      // 主管/支管长度
      sewageLengthList: [],
      rainLengthList: [],

      // 管径长度
      sewageDiameter: {},
      rainDiameter: {},

      drainList: [],
      wellList: [],
    };
  },

  mounted() {
    request({
      url: "/model-analysis/pipe/stat",
    }).then((res) => {
      const { pipeMap = {}, drainMap = {}, wellMap = {} } = res || {};

      const { rain = {}, sewage = {} } = pipeMap;

      this.sewageLength = sewage?.model?.length || 0;
      this.rainLength = rain?.model?.length || 0;

      this.sewageLengthList = [
        {
          color: "#FFA700",
          name: "主管",
          value: sewage?.model?.thickLength || 0,
          total:
            (sewage?.model?.thickLength || 0) +
            (sewage?.model?.thinLength || 0),
        },
        {
          color: "#0AD8F3",
          name: "支管",
          value: sewage?.model?.thinLength || 0,
          total:
            (sewage?.model?.thickLength || 0) +
            (sewage?.model?.thinLength || 0),
        },
      ];

      this.rainLengthList = [
        {
          color: "#FFA700",
          name: "主管",
          value: rain?.model?.thickLength || 0,
          total:
            (rain?.model?.thickLength || 0) + (rain?.model?.thinLength || 0),
        },
        {
          color: "#0AD8F3",
          name: "支管",
          value: rain?.model?.thinLength || 0,
          total:
            (rain?.model?.thickLength || 0) + (rain?.model?.thinLength || 0),
        },
      ];

      if (sewage.diameter && typeof sewage.diameter === "object") {
        this.sewageDiameter = sewage.diameter;
      }
      if (rain.diameter && typeof rain.diameter === "object") {
        this.rainDiameter = rain.diameter;
      }

      this.drainList = Object.keys(drainMap).map((key) => ({
        name: key,
        value: drainMap[key],
      }));
      this.wellList = Object.keys(wellMap).map((key) => ({
        name: key,
        value: wellMap[key],
      }));
    });
  },

  methods: {
    select(text) {
      this.alertText = text;
    },
  },
};
</script>

<style lang="less" scoped>
.left-panel {
  position: fixed;
  top: 12vh;
  left: 4vw;
  width: 28vw;
  z-index: 200;
}

.right-panel {
  position: fixed;
  top: 12vh;
  right: 4vw;
  width: 28vw;
  z-index: 200;
}

.left-panel-bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 25vw;
  bottom: 0;
  background: linear-gradient(to right, #000, rgba(0, 0, 0, 0.1));
  z-index: 199;
}
.right-panel-bg {
  position: fixed;
  top: 0;
  right: 0;
  width: 25vw;
  bottom: 0;
  background: linear-gradient(to left, #000, rgba(0, 0, 0, 0.1));
  z-index: 199;
}

.card {
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0) 0%,
    rgba(47, 118, 181, 0.8) 100%
  );

  & > .header {
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    .title {
      position: absolute;
      top: 0.8vh;
      left: 0;
      color: #fff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 0.9vw;
    }

    .extra {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      color: #fff;
    }
  }

  .body {
    padding: 1.2vh 0.8vw;
  }
}
</style>