<template>
  <div>
    <div class="wrapper">
      <div class="section">
        <div class="list">
          <div class="item">
            <div class="label">
              <img src="@/assets/length-bg.png" alt="" />
              <span>污水管道</span>
            </div>
            <div>
              <span class="value">{{ sewageLength }}</span>
              <span>km</span>
            </div>
          </div>
        </div>
      </div>
      <div class="section">
        <div class="list">
          <div class="item">
            <div class="label">
              <img src="@/assets/length-bg.png" alt="" />
              <span>雨水管道</span>
            </div>
            <div>
              <span class="value">{{ rainLength }}</span>
              <span>km</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="wrapper" style="gap: 0">
      <div style="flex: 1">
        <div class="chart" style="height: 15vh" id="sewage"></div>
        <div class="center" style="gap: 0.8vw; font-size: 1.4vh">
          <div
            v-for="item in sewageLengthList"
            :key="item.name"
            class="center"
            style="gap: 0.2vw"
          >
            <span
              :style="{
                background: item.color,
                height: '1.4vh',
                width: '1.4vh',
              }"
            ></span>
            <span>{{ item.name }}</span>
            <span>{{ (item.value / item.total).toFixed(2) * 100 }}%</span>
          </div>
        </div>
      </div>
      <div style="flex: 1">
        <div class="chart" style="height: 15vh" id="rain"></div>
        <div class="center" style="gap: 0.8vw; font-size: 1.4vh">
          <div
            v-for="item in rainLengthList"
            :key="item.name"
            class="center"
            style="gap: 0.2vw"
          >
            <span
              :style="{
                background: item.color,
                height: '1.4vh',
                width: '1.4vh',
              }"
            ></span>
            <span>{{ item.name }}</span>
            <span>{{ (item.value / item.total).toFixed(2) * 100 }}%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts/core";
import { TooltipComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, PieChart, CanvasRenderer, LabelLayout]);

export default {
  props: {
    sewageLength: {
      type: Number,
      default: 0,
    },
    rainLength: {
      type: Number,
      default: 0,
    },

    sewageLengthList: {
      type: Array,
      default: () => [],
    },

    rainLengthList: {
      type: Array,
      default: () => [],
    },
  },

  beforeDestroy() {
    this.sewageChart?.dispose();
    this.rainChart?.dispose();
  },

  watch: {
    sewageLengthList: {
      handler() {
        if (this.sewageLengthList.length > 0) {
          this.initSewageChart();
        }
      },
      deep: true,
    },
    rainLengthList: {
      handler() {
        if (this.rainLengthList.length > 0) {
          this.initRainChart();
        }
      },
      deep: true,
    },
  },

  methods: {
    initSewageChart() {
      var chartDom = document.getElementById("sewage");
      this.sewageChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#FFA700", "#0AD8F3"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "70%%",
            // radius: ["50%", "65%"],
            avoidLabelOverlap: false,
            label: {
              color: "#fff",
              show: false,
              formatter: ["{title|{b}}", "{percentage|{d}%}"].join("\n"),
              rich: {
                title: {
                  lineHeight: 18,
                },
                percentage: {
                  fontSize: 12,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: this.sewageLengthList,
          },
        ],
      };

      option && this.sewageChart.setOption(option);
    },

    initRainChart() {
      var chartDom = document.getElementById("rain");
      this.rainChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#FFA700", "#0AD8F3"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "70%%",
            avoidLabelOverlap: false,
            label: {
              color: "#fff",
              show: false,
              formatter: ["{title|{b}}", "{percentage|{d}%}"].join("\n"),
              rich: {
                title: {
                  lineHeight: 18,
                },
                percentage: {
                  fontSize: 12,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              show: false,
            },
            data: this.rainLengthList,
          },
        ],
      };

      option && this.rainChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  gap: 1vw;

  .section {
    flex: 1;
    display: flex;
    align-items: center;

    .list {
      flex: 1;
      display: flex;
      justify-content: space-around;
      .item {
        text-align: center;
        color: #fff;
        .value {
          font-size: 2vh;
          font-weight: bold;
          color: #299bc5;
          padding-right: 0.2vw;
        }

        .label {
          position: relative;
          margin-bottom: 1vh;
          img {
            width: 100%;
            display: block;
          }
          span {
            position: absolute;
            top: 15%;
            left: 0;
            bottom: 0;
            right: 0;
            text-align: center;
            font-size: 0.6vw;
          }
        }
      }
    }
  }
}
</style>