import { fetchAlertList, } from "@/api/alert";
import { PointLayer, LayerPopup } from '@antv/l7'

export default {
    methods: {
        getAlertList() {
            if (!this.alertLayer) {
            fetchAlertList().then((res) => {

                const zoom = this.map?.getZoom() || 3;

                if (res && Array.isArray(res.list)) {
                    this.alertLayer = new PointLayer({
                        zIndex: 999,
                    })
                        .source(res.list, {
                            parser: {
                                type: "json",
                                x: "x",
                                y: "y",
                            },
                        })
                        .shape("alert")
                        .size(zoom * 0.55);

                    this.scene?.addLayer(this.alertLayer);

                    const popup = new LayerPopup({
                        items: [
                            {
                                layer: this.alertLayer,
                                customContent: feature => `
                                    <div class='alert-popup'>
                                        <div class='header'>
                                            <div class='title'>${feature.name}</div>
                                        </div>
                                        <div class='body'>
                                            <div class='item'>
                                                <div class='label'>告警内容</div>
                                                <div classs='value' style='color: #f00'>${feature.remark}</div>
                                            </div>
                                            <div class='item'>
                                                <div class='label'>告警时间</div>
                                                <div classs='value'>${feature.alertTime}</div>
                                            </div>
                                        </div>
                                    </div>
                                `
                            },
                        ],
                        trigger: "hover",
                        // trigger: "click",
                    });
                    this.scene?.addPopup(popup);
                }
            });

                 } else {
                    this.alertLayer.show();
                 }
   
        },
        removeAlertList() {
            if (this.alertLayer) {
                   this.alertLayer.hide();
            }
        }
    }
}