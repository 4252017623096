<template>
  <div class="wrapper" style="gap: 0">
    <div class="chart" style="height: 17vh; flex: 1" id="well"></div>
    <!-- <div class="chart" style="height: 17vh; flex: 1" id="drain"></div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as echarts from "echarts/core";
import { TooltipComponent } from "echarts/components";
import { PieChart } from "echarts/charts";
import { LabelLayout } from "echarts/features";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([TooltipComponent, PieChart, CanvasRenderer, LabelLayout]);

export default {
  props: {
    wellList: {
      type: Array,
      default: () => [],
    },

    drainList: {
      type: Array,
      default: () => [],
    },
  },

  beforeDestroy() {
    this.wellChart?.dispose();
    // this.drainChart?.dispose();
  },

  watch: {
    wellList: {
      handler() {
        if (this.wellList.length > 0) {
          this.initwellChart();
        }
      },
      deep: true,
    },
    // drainList: {
    //   handler() {
    //     if (this.drainList.length > 0) {
    //       this.initdrainChart();
    //     }
    //   },
    //   deep: true,
    // },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    wellType() {
      return this.findDataDict("wellType");
    },
  },

  methods: {
    initwellChart() {
      var chartDom = document.getElementById("well");
      this.wellChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#FFA700", "#0AD8F3"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "40%",
            // radius: ["50%", "65%"],
            avoidLabelOverlap: false,
            label: {
              color: "#fff",
              show: true,
              formatter: ["{title|{b}}", "{percentage|{d}%}"].join("\n"),
              rich: {
                title: {
                  lineHeight: 18,
                },
                percentage: {
                  fontSize: 12,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              show: true,
            },
            data: this.wellList.map((item) => {
              // let name = "";

              // if (item.name === "drain_grate") {
              //   name = "篦子";
              // } else if (item.name === "drain_manhole") {
              //   name = "窨井";
              // } else if (item.name === "drain_septic") {
              //   name = "化粪池";
              // } else if (item.name === "drain_sedimentation") {
              //   name = "沉淀池";
              // } else if (item.name === "mud_well") {
              //   name = "沉泥井";
              // } else {
              //   name = "未配置";
              // }

              const obj = this.wellType.find(
                (element) => element.value === item.name
              );
              return {
                name: obj ? obj.name : "未配置",
                value: item.value,
              };
            }),
          },
        ],
      };

      option && this.wellChart.setOption(option);
    },

    initdrainChart() {
      var chartDom = document.getElementById("drain");
      this.drainChart = echarts.init(chartDom);
      var option;

      option = {
        color: ["#FFA700", "#0AD8F3"],
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            name: "",
            type: "pie",
            radius: "40%",
            avoidLabelOverlap: false,
            label: {
              color: "#fff",
              show: true,
              formatter: ["{title|{b}}", "{percentage|{d}%}"].join("\n"),
              rich: {
                title: {
                  lineHeight: 18,
                },
                percentage: {
                  fontSize: 12,
                  fontWeight: "bold",
                },
              },
            },
            labelLine: {
              show: true,
            },
            data: this.drainList,
          },
        ],
      };

      option && this.drainChart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  display: flex;
  gap: 1vw;

  .section {
    flex: 1;
    position: relative;
    display: flex;
    align-items: center;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      width: 2px;
      background: linear-gradient(
        180deg,
        rgba(103, 146, 127, 0) 0%,
        #67927f 53%,
        rgba(103, 146, 127, 0) 100%
      );
    }

    &:last-child::after {
      background: transparent;
    }

    img {
      height: 4vh;
    }
    .list {
      flex: 1;
      display: flex;
      justify-content: space-around;
      .item {
        text-align: center;
        .value {
          font-size: 2vh;
          font-weight: bold;
          color: #299bc5;
          margin-bottom: 0.2vh;
        }
        .label {
          font-size: 1.6vh;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.75);
        }
      }
    }
  }
}
</style>