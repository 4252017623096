import { fetchSewagePipe, fetchSewagePipeDetail } from "@/api/sewage";
import { LineLayer } from '@antv/l7'

// import SewagePipeDetail from '@/components/sewage-pipe-detail.vue'

export default {

    // components: {
    //     SewagePipeDetail,
    // },

    data() {
        return {
            sewagePipeDetail: {},
            sewagePipeVisible: false,
        }
    },

    watch: {
        sewagePipeVisible() {
            if (!this.sewagePipeVisible) {
                // 关闭弹窗
                this.closePipeActive();
            }
        }
    },

    methods: {
        getSewagePipe() {
            if (!this.sewagePipeLayer) {

                fetchSewagePipe({}).then((res) => {
                    if (res && Array.isArray(res.locs)) {

                        let arr = []
                        for (let i = 0; i < res.locs.length; i++) {
                            const item = res.locs[i]

                            const startLnglat = [parseFloat(item[1]), parseFloat(item[2])]
                            const endLnglat = [parseFloat(item[3]), parseFloat(item[4])]

                            arr.push({
                                "type": "Feature",
                                "properties": {
                                    id: item[0],
                                    startLnglat,
                                    endLnglat
                                },
                                "geometry": {
                                    "type": "LineString",
                                    "coordinates": [
                                        startLnglat,
                                        endLnglat,
                                    ]
                                }
                            })

                        }

                        const sources = {
                            "type": "FeatureCollection",
                            "features": arr
                        }

                        const sewagePipeLayer = new LineLayer({
                            zIndex: 10,
                            depth: true
                        }).source(sources).size(1)
                            .shape('line')
                            .color('#FFA500')
                            // .color('#1990FF')
                            .animate({
                                interval: 1, // 间隔
                                duration: 2, // 持续时间，延时
                                trailLength: 2 // 流线长度
                            });

                        // sewagePipeLayer.on('click', this.onSewagePipeClick);
                        this.sewagePipeLayer = sewagePipeLayer;
                        this.scene?.addLayer(sewagePipeLayer);
                    }
                });
            } else {
                this.sewagePipeLayer?.show();
                this.activeSewagePipeLayer?.show();
                // if (this.sewagePipeDetail.id) {
                //     this.sewagePipeVisible = true;
                // }
            }
        },

        onSewagePipeClick(e) {

            this.setPipeActive(e.feature.properties, 'sewagePipeVisible')

            const { id } = e.feature.properties;
            fetchSewagePipeDetail({
                ids: [id],
            }).then((res) => {
                if (Array.isArray(res) && res.length > 0) {
                    this.sewagePipeDetail = res[0];
                }
            });

        },



        setPipeActive(properties, key) {

            const sources = {
                "type": "FeatureCollection",
                "features": [{
                    "type": "Feature",
                    properties,
                    "geometry": {
                        "type": "LineString",
                        "coordinates": [
                            properties.startLnglat,
                            properties.endLnglat
                        ]
                    }

                }]
            }

            this.closePipeActive();

            this.activePipeLayer = new LineLayer({
                zIndex: 900,
                depth: true
            }).source(sources).size(5)
                .shape('line')
                .color('red')
                .animate({
                    interval: 1, // 间隔
                    duration: 2, // 持续时间，延时
                    trailLength: 2 // 流线长度
                });

            this.activePipeLayer.on('click', () => {
                this.closePipeActive();
                this[key] = false;
            });

            this.scene?.addLayer(this.activePipeLayer);
            this[key] = true;

        },

        closePipeActive() {
            if (this.activePipeLayer) {
                this.scene?.removeLayer(this.activePipeLayer);
                this.activePipeLayer = null;
            }
        },

        removeSewagePipe() {
            this.sewagePipeVisible = false;
            this.sewagePipeLayer?.hide();
            this.activeSewagePipeLayer?.hide();
        },

    }
}

