<template>
  <div class="weather">
    <div class="weather-wrapper" v-if="hours.length > 0">
      <div class="item" v-for="(item, index) in hours" :key="index">
        <div class="date">{{ item.hours }}</div>
        <div class="tem">{{ item.tem }}℃</div>
        <img v-if="item.wea_img" :src="getImagePath(item.wea_img)" alt />
        <div>{{ item.wea }}</div>
      </div>
    </div>
    <div v-else style="text-align: center; padding: 1vh 0">暂无数据</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      hours: [],
    };
  },

  mounted() {
    this.getWeatherForeCast();
  },
  methods: {
    getWeatherForeCast() {
      fetch("https://adm.ac.cn/Api/Weather/getZaoyang24?token=njszy")
        .then((res) => res.json())
        .then((res) => {
          console.log("res", res);
          if (res && res.info && Array.isArray(res.info.hours)) {
            this.hours = res.info.hours;
          }
        });
    },

    getImagePath(name) {
      return `http://adm.ac.cn/static/img/weather/mango/${name}.png`;
    },
  },
};
</script>


<style lang="less" scoped>
.weather {
  width: 23vw;
  overflow-x: auto;
  font-size: 1.2vh;
  font-weight: 400;
  color: #fff;

  .weather-wrapper {
    display: flex;
  }

  .item {
    display: flex;
    flex-direction: column;
    gap: 0.4vh;
    align-items: center;
    min-width: 6em;
    text-align: center;
    img {
      width: 1.8vw;
    }
  }
}
</style>
