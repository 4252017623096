import { PointLayer, Popup } from '@antv/l7';
import { mapState } from 'vuex'

import request from '@/api/request'

export default {

  computed: {
    ...mapState('home', ['facilityList'])
  },

  watch: {
    facilityList() {
      console.log('factility list changed', this.facilityList)
      if (this.facilityList.length > 0) {
        this.getMonitor();
        this.getLiquid();
        this.getFlow();
        this.getCuto();
        this.getRainfall();
        this.getWaterQuality();
      }
    },

  },

  methods: {
    getLiquid() {
      if (!this.liquidLayer) {
        const zoom = this.map?.getZoom() || 3;

        this.liquidLayer = new PointLayer({
          zIndex: 100,

        }).source(this.facilityList.filter(item => item.type === 'liquidLevel'), {
          parser: {
            type: 'json',
            x: 'x',
            y: 'y'
          }
        })
          .shape('liquidLevel')
          .size(zoom * 0.55)

        this.liquidLayer.on('mouseenter', this.liquidEnter);
        this.liquidLayer.on('mouseout', this.liquidOut);
        this.liquidLayer.on('click', this.liquidEnter);
        this.liquidLayer.on('unclick', this.liquidOut);


        this.scene?.addLayer(this.liquidLayer);
      } else {
        this.liquidLayer.show();
      }

    },
    removeLiquid() {
      this.liquidLayer?.hide();
    },

    liquidEnter(e) {
      console.log('feature', e.feature)
      const { sn } = e.feature;
      if (!sn) {
        this.$message.error("没有SN");
        return;
      }
      this.getLiquidData(e.feature);
    },

    liquidOut() {
      this.liquidDetail = {}
      if (this.popup) {
        this.scene?.removePopup(this.popup);
        this.popup = null;
      }

    },

    getLiquidData({ x, y, sn, name }) {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: "liquidLevel",
          eids: [sn],
        },
      }).then((res) => {
        this.liquidDetail = res ? { ...res, sn, name } : {}

        this.popup = new Popup({
          lngLat: {
            lng: x,
            lat: y,
          },
          html: this.setLiquidContent(this.liquidDetail),
          closeButton: false,
        });
        this.scene?.addPopup(this.popup);


      })

    },

    setLiquidContent({ name, liquidLevel, distance, ts }) {
      return `
                      <div class='alert-popup'>
                                <div class='header'>
                                    <div class='title'>${name}</div>
                                </div>
                                <div class='body'>
                                    <div class='item'>
                                        <div class='label'>液位</div>
                                        <div classs='value'>${typeof liquidLevel === 'number' ? liquidLevel + 'm' : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>距离井口深度</div>
                                        <div classs='value'>${typeof liquidLevel === 'number' ? distance + 'm' : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>更新时间</div>
                                        <div classs='value'>${ts}</div>
                                    </div>
                                </div>
                            </div>
            `
    },

    getFlow() {
      if (!this.flowLayer) {
        const zoom = this.map?.getZoom() || 3;

        this.flowLayer = new PointLayer({
          zIndex: 100,
        }).source(this.facilityList.filter(item => item.type === 'flowMeter'), {
          parser: {
            type: 'json',
            x: 'x',
            y: 'y'
          }
        })
          .shape('flowMeter')
          .size(zoom * 0.55)

        this.flowLayer.on('mouseenter', this.flowEnter);
        this.flowLayer.on('mouseout', this.flowOut);
        this.flowLayer.on('click', this.flowEnter);
        this.flowLayer.on('unclick', this.flowOut);

        this.scene?.addLayer(this.flowLayer);
      } else {
        this.flowLayer.show();
      }

    },
    removeFlow() {
      this.flowLayer?.hide();
    },

    flowEnter(e) {
      console.log('feature', e.feature)
      const { sn } = e.feature;
      if (!sn) {
        this.$message.error("没有SN");
        return;
      }
      this.getFlowData(e.feature);
    },

    flowOut() {
      this.flowDetail = {}
      if (this.popup) {
        this.scene?.removePopup(this.popup);
        this.popup = null;
      }

    },

    getFlowData({ x, y, type, sn, name }) {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: type,
          eids: [sn],
        },
      }).then((res) => {
        this.flowDetail = res ? { ...res, sn, name } : {}

        this.popup = new Popup({
          lngLat: {
            lng: x,
            lat: y,
          },
          html: this.setFlowContent(this.flowDetail),
          closeButton: false,
        });
        this.scene?.addPopup(this.popup);


      })

    },

    setFlowContent({ name, flowRate, liquidLevel, temperature, distance, ts, instantaneousFlow }) {
      return `
                      <div class='alert-popup'>
                                <div class='header'>
                                    <div class='title'>${name}</div>
                                </div>
                                <div class='body'>
                                                   <div class='item'>
                                        <div class='label'>流速</div>
                                        <div classs='value'>${typeof flowRate === 'number' ? flowRate + "m/s" : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>流量</div>
                                        <div classs='value'>${typeof instantaneousFlow === 'number' ? instantaneousFlow + 'm3/h' : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>距离井盖深度</div>
                                        <div classs='value'>${typeof distance === 'number' ? distance + 'm' : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>液位</div>
                                        <div classs='value'>${typeof liquidLevel === 'number' ? liquidLevel + 'm' : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>温度</div>
                                        <div classs='value'>${typeof temperature === 'number' ? temperature + '℃' : '--'}</div>
                                    </div>
                                    
                                    <div class='item'>
                                        <div class='label'>更新时间</div>
                                        <div classs='value'>${ts}</div>
                                    </div>
                                </div>
                            </div>
            `
    },

    getCuto() {
      if (!this.cutoLayer) {
        const zoom = this.map?.getZoom() || 3;

        this.cutoLayer = new PointLayer({
          zIndex: 100,
        }).source(this.facilityList.filter(item => item.type === 'cutoffWell'), {
          parser: {
            type: 'json',
            x: 'x',
            y: 'y'
          }
        })
          .shape('cutoffwell')
          .size(zoom * 0.55)

        this.cutoLayer.on('mouseenter', this.cutoEnter);
        this.cutoLayer.on('mouseout', this.cutoOut);

        this.cutoLayer.on('click', this.cutoEnter);
        this.cutoLayer.on('unclick', this.cutoOut);

        this.scene?.addLayer(this.cutoLayer);
      } else {
        this.cutoLayer.show();
      }
    },
    removeCuto() {
      this.cutoLayer?.hide();
    },

    cutoEnter(e) {
      console.log('feature', e.feature)
      const { sn } = e.feature;
      if (!sn) {
        this.$message.error("没有SN");
        return;
      }
      this.getcutoData(e.feature);
    },

    cutoOut() {
      this.cutoDetail = {}
      if (this.popup) {
        this.scene?.removePopup(this.popup);
        this.popup = null;
      }

    },

    getcutoData({ x, y, subType, sn, name }) {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: subType,
          eids: [sn],
        },
      }).then((res) => {
        this.cutoDetail = res ? { ...res, sn, name, subType } : {}

        this.popup = new Popup({
          lngLat: {
            lng: x,
            lat: y,
          },
          html: this.setcutoContent(this.cutoDetail),
          closeButton: false,
        });
        this.scene?.addPopup(this.popup);


      })

    },

    setcutoContent(realTimeData) {

      let content = '';

      if (realTimeData.subType === 'intel_cutoffWell_shengyu_yeya') {
        content = `
                                 <div class="item">
        <div class="label">井内液位</div>
        <div
          class="value"
        >${realTimeData.liquidLevelInner !== undefined ? realTimeData.liquidLevelInner + "mm" : "--"}</div>
      </div>
      <div class="item">
        <div class="label">井外液位</div>
        <div class="value">
          ${realTimeData.liquidLevelOuter !== undefined ?
            (realTimeData.liquidLevelOuter > 0 ? realTimeData.liquidLevelOuter : 0) + "mm" : "--"}
        </div>
      </div>
      <div class="item">
        <div class="label">污水液位</div>
        <div class="value">
          ${realTimeData.liquidLevelDirtyPipe !== undefined ?
            (realTimeData.liquidLevelDirtyPipe > 0 ? realTimeData.liquidLevelDirtyPipe : 0) + "mm" : "--"}
        </div>
      </div>
      <div class="item">
        <div class="label">SS</div>
        <div class="value">${realTimeData.ss !== undefined ? realTimeData.ss + 'mg/l' : "--"}</div>
      </div>
      <div class="item">
        <div class="label">雨量</div>
        <div
          class="value"
        >${realTimeData.precipitation !== undefined ? realTimeData.precipitation + 'dmm' : "--"}</div>
      </div>

      <div class="item">
        <div class="label">1#气囊压力</div>
        <div
          class="value"
        >${realTimeData.pressureAirbag1 !== undefined ? realTimeData.pressureAirbag1 + 'kpa' : "--"}</div>
      </div>

      <div class="item">
        <div class="label">2#气囊压力</div>
        <div
          class="value"
        >${realTimeData.pressureAirbag2 !== undefined ? realTimeData.pressureAirbag2 + 'kpa' : "--"}</div>
      </div>
      <div class="item">
        <div class="label">1#闸门状态</div>
        <div class="value">
          ${realTimeData.isIntakeValveOpenAirbag1
            !== undefined ? (realTimeData.isIntakeValveOpenAirbag1 === 0 ? '关' : '开') : "--"}
        </div>
      </div>

      <div class="item">
        <div class="label">2#闸门状态</div>
        <div class="value">
          ${realTimeData.isIntakeValveOpenAirbag2
            !== undefined ? (realTimeData.isIntakeValveOpenAirbag2 === 0 ? '关' : '开') : "--"}
        </div>
      </div>

      <div class="item">
        <div class="label">工作状态</div>
        <div class="value">
          ${realTimeData.workStatus
            !== undefined ? realTimeData.workStatus
            : "--"}
        </div>
      </div>

      <div class="item">
        <div class="label">运行模式</div>
        <div class="value">
          ${realTimeData.isAutoMode
            !== undefined ? (realTimeData.isAutoMode == 1 ? '自动' : '手动')
            : "--"}
        </div>
      </div>
      <div class="item">
        <div class="label">外部采集SS</div>
        <div
          class="value"
        >${typeof realTimeData.claatekSs === 'number' ? realTimeData.claatekSs + 'mg/l' : "--"}</div>
      </div>  
                `
      }

      else if (realTimeData.subType === 'intel_cutoffWell_shengyu_other') {
        content = `
                      <div class="item">
        <div class="label">井内液位</div>
        <div
          class="value"
        >${realTimeData.liquidLevelInner !== undefined ? realTimeData.liquidLevelInner + "mm" : "--"}</div>
      </div>
      <div class="item">
        <div class="label">井外液位</div>
        <div class="value">
          ${realTimeData.liquidLevelOuter !== undefined ?
            (realTimeData.liquidLevelOuter > 0 ? realTimeData.liquidLevelOuter : 0) + "mm" : "--"}
        </div>
      </div>
      <div class="item">
        <div class="label">污水液位</div>
        <div class="value">
          ${realTimeData.liquidLevelDirtyPipe !== undefined ?
            realTimeData.liquidLevelDirtyPipe + "mm" : "--"}
        </div>
      </div>

      <div class="item">
        <div class="label">SS</div>
        <div class="value">${realTimeData.ss !== undefined ? realTimeData.ss + 'mg/l' : "--"}</div>
      </div>
      <div class="item">
        <div class="label">雨量</div>
        <div
          class="value"
        >${realTimeData.precipitation !== undefined ? realTimeData.precipitation + 'dmm' : "--"}</div>
      </div>

      <div class="item">
        <div class="label">1#气囊压力</div>
        <div
          class="value"
        >${realTimeData.pressureAirbag1 !== undefined ? realTimeData.pressureAirbag1 + 'kpa' : "--"}</div>
      </div>
      <div class="item">
        <div class="label">1#气囊开度</div>
        <div
          class="value"
        >${realTimeData.gateOpeningAirbag1 !== undefined ? realTimeData.gateOpeningAirbag1 + '%' : "--"}</div>
      </div>

      <div class="item">
        <div class="label">2#气囊压力</div>
        <div
          class="value"
        >${realTimeData.pressureAirbag2 !== undefined ? realTimeData.pressureAirbag2 + 'kpa' : "--"}</div>
      </div>
      <div class="item">
        <div class="label">2#气囊开度</div>
        <div
          class="value"
        >${realTimeData.gateOpeningAirbag2 !== undefined ? realTimeData.gateOpeningAirbag2 + '%' : "--"}</div>
      </div>

      <div class="item">
        <div class="label">工作状态</div>
        <div class="value">
          ${realTimeData.workStatus
            !== undefined ? realTimeData.workStatus
            : "--"}
        </div>
      </div>

      <div class="item">
        <div class="label">运行模式</div>
        <div class="value">
          ${this.getMode(realTimeData.workMode)}
        </div>
      </div>
      <div class="item">
        <div class="label">外部采集SS</div>
        <div
          class="value"
        >${typeof realTimeData.claatekSs === 'number' ? realTimeData.claatekSs + 'mg/l' : "--"}</div>
      </div>
                `
      } else if (realTimeData.subType === 'intel_cutoffWell_qirun_main') {
        content = `
     <div class="item">
        <div class="label">液位</div>
        <div
          class="value"
        >${realTimeData.liquidLevel !== undefined ? realTimeData.liquidLevel + "m" : "--"}</div>
      </div>

      <div class="item">
        <div class="label">外部采集SS</div>
        <div
          class="value"
        >${typeof realTimeData.claatekSs === 'number' ? realTimeData.claatekSs + 'mg/l' : "--"}</div>
      </div>
      <div class="item">
        <div class="label">雨量</div>
        <div class="value">${realTimeData.rainfall !== undefined ? realTimeData.rainfall + 'dmm' : "--"}</div>
      </div>

      <div class="item">
        <div class="label">油压</div>
        <div
          class="value"
        >${realTimeData.gateOilPressure !== undefined ? realTimeData.gateOilPressure + 'kpa' : "--"}</div>
      </div>
      <div class="item">
        <div class="label">截留闸开度</div>
        <div
          class="value"
        >${realTimeData.cutoffGateOpening !== undefined ? realTimeData.cutoffGateOpening : "--"}</div>
      </div>

      <div class="item">
        <div class="label">出水闸开度</div>
        <div
          class="value"
        >${realTimeData.outputGateOpening !== undefined ? realTimeData.outputGateOpening : "--"}</div>
      </div>
      <div class="item">
        <div class="label">cod</div>
        <div class="value">${realTimeData.cod !== undefined ? realTimeData.cod + 'mg/L' : "--"}</div>
      </div>

      <div class="item">
        <div class="label">油温报警状态位</div>
        <div class="value">
          ${realTimeData.isOilTemperatureWarn
            ? '异常'
            : "正常"}
        </div>
      </div>

      <div class="item">
        <div class="label">油泵液位报警状态位</div>
        <div class="value">
          ${realTimeData.isOilLiquidLevelWarn
            ? '正常'
            : "异常"}
        </div>
      </div>

      <div class="item">
        <div class="label">运行模式</div>
        <div class="value">
          ${realTimeData.isLocalMode
            === true ? '本地模式' : (realTimeData.isAutoMode === true ? '自动模式' : 'unknown')
          }
        </div>
      </div>
                `
      }

      return `
                      <div class='alert-popup'>
                                <div class='header'>
                                    <div class='title'>${realTimeData.name}</div>
                                </div>
                                <div class='body' style='  display: grid;
  grid-template-columns: repeat(2,1fr);'>
                                    ${content}
                                    <div class='item'>
                                        <div class='label'>更新时间</div>
                                        <div classs='value'>${realTimeData.ts ? realTimeData.ts : "--"}</div>
                                    </div>
                                </div>
                            </div>
            `
    },

    getMonitor() {
      if (!this.monitorLayer) {
        const zoom = this.map?.getZoom() || 3;

        this.monitorLayer = new PointLayer({
          zIndex: 100,
        }).source(this.facilityList.filter(item => item.type === 'video'), {
          parser: {
            type: 'json',
            x: 'x',
            y: 'y'
          }
        })
          .shape('video')
          .size(zoom * 0.55)

        // const that = this;
        // this.monitorLayer.on('click', function (e) {
        //     console.log('e', e)
        //     that.monitorDetail = e.feature;
        //        that.setFacilityActive(e.feature, 'monitorVisible');
        // })
        this.scene?.addLayer(this.monitorLayer);
      } else {
        this.monitorLayer.show();
      }

    },
    removeMonitor() {
      this.monitorLayer?.hide();
    },

    getRainfall() {
      if (!this.rainfallLayer) {
        const zoom = this.map?.getZoom() || 3;

        this.rainfallLayer = new PointLayer({
          zIndex: 100,

        }).source(this.facilityList.filter(item => item.type && item.type.toUpperCase() === 'rainFall'.toUpperCase()), {
          parser: {
            type: 'json',
            x: 'x',
            y: 'y'
          }
        })
          .shape('rainFall')
          .size(zoom * 0.55)

        this.rainfallLayer.on('mouseenter', this.rainEnter);
        this.rainfallLayer.on('mouseout', this.rainOut);

        this.rainfallLayer.on('click', this.rainEnter);
        this.rainfallLayer.on('unclick', this.rainOut);

        this.scene?.addLayer(this.rainfallLayer);
      } else {
        this.rainfallLayer.show();
      }

    },
    removeRainfall() {
      this.rainfallLayer?.hide();
    },

    rainEnter(e) {
      console.log('feature', e.feature)
      const { sn } = e.feature;
      if (!sn) {
        this.$message.error("没有SN");
        return;
      }
      this.getrainData(e.feature);
    },

    rainOut() {
      this.rainDetail = {}
      if (this.popup) {
        this.scene?.removePopup(this.popup);
        this.popup = null;
      }

    },

    getrainData({ x, y, type, sn, name }) {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: type,
          eids: [sn],
        },
      }).then((res) => {
        this.rainDetail = res ? { ...res, sn, name } : {}

        this.popup = new Popup({
          lngLat: {
            lng: x,
            lat: y,
          },
          html: this.setrainContent(this.rainDetail),
          closeButton: false,
        });
        this.scene?.addPopup(this.popup);


      })

    },

    setrainContent({ name, rainfall, ts }) {
      return `
                      <div class='alert-popup'>
                                <div class='header'>
                                    <div class='title'>${name}</div>
                                </div>
                                <div class='body'>
                         
                                    <div class='item'>
                                        <div class='label'>降雨</div>
                                        <div classs='value'>${typeof rainfall === 'number' ? rainfall + 'mm' : '--'}</div>
                                    </div>
                                    
                                    <div class='item'>
                                        <div class='label'>更新时间</div>
                                        <div classs='value'>${ts}</div>
                                    </div>
                                </div>
                            </div>
            `
    },

    getWaterQuality() {
      if (!this.waterQualityLayer) {
        const zoom = this.map?.getZoom() || 3;

        this.waterQualityLayer = new PointLayer({
          zIndex: 100,
        }).source(this.facilityList.filter(item => item.type === 'waterquality'), {
          parser: {
            type: 'json',
            x: 'x',
            y: 'y'
          }
        })
          .shape('water-quality')
          .size(zoom * 0.55)

        this.waterQualityLayer.on('mouseenter', this.waterQualityEnter);
        this.waterQualityLayer.on('mouseout', this.waterQualityOut);

        this.waterQualityLayer.on('click', this.waterQualityEnter);
        this.waterQualityLayer.on('unclick', this.waterQualityOut);

        this.scene?.addLayer(this.waterQualityLayer);
      } else {
        this.waterQualityLayer.show();
      }

    },
    removeWaterQuality() {
      this.waterQualityLayer?.hide();
    },

    waterQualityEnter(e) {
      console.log('feature', e.feature)
      const { sn } = e.feature;
      if (!sn) {
        this.$message.error("没有SN");
        return;
      }
      this.getwaterQualityData(e.feature);
    },

    waterQualityOut() {
      this.waterQualityDetail = {}
      if (this.popup) {
        this.scene?.removePopup(this.popup);
        this.popup = null;
      }

    },

    getwaterQualityData({ x, y, type, sn, name }) {
      request({
        url: "/data-service/dev/realTime",
        method: "post",
        data: {
          devType: type,
          eids: [sn],
        },
      }).then((res) => {
        this.waterQualityDetail = res ? { ...res, sn, name } : {}

        this.popup = new Popup({
          lngLat: {
            lng: x,
            lat: y,
          },
          html: this.setwaterQualityContent(this.waterQualityDetail),
          closeButton: false,
        });
        this.scene?.addPopup(this.popup);


      })

    },

    setwaterQualityContent({ name, conductivity, turbidity, cod, ph, nh3n, temperature, ts }) {
      return `
                      <div class='alert-popup'>
                                <div class='header'>
                                    <div class='title'>${name}</div>
                                </div>
                                <div class='body'>
                                                                <div class='item'>
                                        <div class='label'>电导率</div>
                                        <div classs='value'>${typeof conductivity === 'number' ? conductivity + 'uS/cm' : '--'}</div>
                                    </div>
                                                                <div class='item'>
                                        <div class='label'>浊度</div>
                                        <div classs='value'>${typeof turbidity === 'number' ? turbidity + 'NTU' : '--'}</div>
                                    </div>
                                                              
                                                  <div class='item'>
                                        <div class='label'>化学需氧量</div>
                                        <div classs='value'>${typeof cod === 'number' ? cod + 'mg/L' : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>酸碱度</div>
                                        <div classs='value'>${typeof ph === 'number' ? ph : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>氨氮</div>
                                        <div classs='value'>${typeof nh3n === 'number' ? nh3n + 'mg/L' : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>温度</div>
                                        <div classs='value'>${typeof temperature === 'number' ? temperature + '℃' : '--'}</div>
                                    </div>
                                    <div class='item'>
                                        <div class='label'>更新时间</div>
                                        <div classs='value'>${ts}</div>
                                    </div>
                                </div>
                            </div>
            `
    },

    getMode(value) {
      if (String(value) === '0') {
        return '系统暂停'
      } else if (String(value) === '1') {
        return '本地手动'
      } else if (String(value) === '2') {
        return '本地自动'
      } else if (String(value) === '3') {
        return '远程模式'
      } else {
        return '未知'
      }
    },

    setFacilityActive(info, key) {
      this.closeFacilityActive();

      const zoom = this.map?.getZoom() || 3;

      this.facilityActiveLayer = new PointLayer({
        zIndex: 10000,
      })
        .source([info], {
          parser: {
            type: 'json',
            x: 'x',
            y: 'y'
          }
        })
        .shape('simple')
        .color('#ff0000')
        .size(zoom * 0.6)


      const that = this;
      this.facilityActiveLayer.on('click', function () {
        that.closeFacilityActive();
        that[key] = false;
      })

      this.scene?.addLayer(this.facilityActiveLayer);
      this[key] = true


      this.scene?.setCenter([info.x, info.y], {
      });

    },

    closeFacilityActive() {
      if (this.facilityActiveLayer) {
        this.scene?.removeLayer(this.facilityActiveLayer);
        this.facilityActiveLayer = null;
      }
    }
  }
}