<template>
  <div>
    <div id="map"></div>

    <ControlBar :rotation="rotation" @rotate="rotate" />

    <ToolBar :selected="selected" @change="onSelect" />
  </div>
</template>
<script>
import area from "@/mixins/area";
import mapTools from "@/mixins/map-tools";
import sewagePipe from "@/mixins/sewage-pipe";
import rainPipe from "@/mixins/rain-pipe";

import ToolBar from "./tool-bar.vue";

import { Scene, ExportImage } from "@antv/l7";
import { GaodeMapV2 } from "@antv/l7-maps";

import facility from "./mixins/facility";
import alert from "./mixins/alert";

import river from "./mixins/river";

import { debounce } from "lodash";
import { saveAs } from "file-saver";

import ControlBar from "@/components/control-bar/index.vue";

export default {
  props: {
    alertText: {
      type: Object,
      default: () => ({}),
    },
  },

  components: {
    ToolBar,
    ControlBar,
  },
  mixins: [area, mapTools, sewagePipe, rainPipe, facility, alert, river],

  data() {
    return {
      rotation: 0,
      selected: [
        "视频监控",
        "流量计",
        "液位计",
        "雨量计",
        "水质监测站",
        "智慧截流井",
        "污水管网",
        "雨水管网",
        "告警列表",
      ],
    };
  },

  watch: {
    selected() {
      if (this.selected.indexOf("视频监控") > -1) {
        this.getMonitor();
      } else {
        this.removeMonitor();
      }
      if (this.selected.indexOf("液位计") > -1) {
        this.getLiquid();
      } else {
        this.removeLiquid();
      }
      if (this.selected.indexOf("流量计") > -1) {
        this.getFlow();
      } else {
        this.removeFlow();
      }
      if (this.selected.indexOf("智慧截流井") > -1) {
        this.getCuto();
      } else {
        this.removeCuto();
      }
      if (this.selected.indexOf("雨量计") > -1) {
        this.getRainfall();
      } else {
        this.removeRainfall();
      }
      if (this.selected.indexOf("水质监测站") > -1) {
        this.getWaterQuality();
      } else {
        this.removeWaterQuality();
      }
      if (this.selected.indexOf("污水管网") > -1) {
        this.getSewagePipe();
      } else {
        this.removeSewagePipe();
      }
      if (this.selected.indexOf("雨水管网") > -1) {
        this.getrainpipe();
      } else {
        this.removerainpipe();
      }
      if (this.selected.indexOf("告警列表") > -1) {
        this.getAlertList();
      } else {
        this.removeAlertList();
      }
    },

    alertText: {
      handler() {
        console.log("text", this.alertText);
        this.setFacilityActive(this.alertText, "alertVisible");
      },
      deep: true,
    },
  },

  mounted() {
    this.debounceZoomChange = debounce(this.onZoomChange, 150);

    this.initMap();
    this.getSewagePipe();
    this.getrainpipe();
    this.getAlertList();

    this.getRiverList();
  },

  beforeDestroy() {
    this.map?.off("click", this.onMapClick);
    this.map?.off("zoomend", this.debounceZoomChange);

    this.map?.off("rotatechange", this.rotatechange);

    this.clearRiverList();
    this.map?.clearMap();

    this.scene?.destroy();
    this.map?.destroy();
  },

  methods: {
    initMap() {
      const { AMap } = window;
      if (!AMap) {
        return;
      }

      var map = new AMap.Map("map", {
        viewMode: "3D",
        terrain: true, // 开启地形图

        center: [119.056708, 33.583976],
        zoom: 14,
        mapStyle: "amap://styles/52faa27d18984923cceae498330eceb1",

        WebGLParams: {
          preserveDrawingBuffer: true,
        },
      });

      map.on("rotatechange", this.rotatechange);

      this.map = map;

      // "AMap.Scale", "AMap.HawkEye",
      // AMap.plugin(["AMap.ControlBar"], function () {
      //   // map.addControl(new AMap.Scale());
      //   // map.addControl(new AMap.HawkEye({ isOpen: true }));
      //   // map.addControl(new AMap.MapType());

      //   const controlBar = new AMap.ControlBar({
      //     position: {
      //       top: "16vh",
      //       right: "1vw",
      //     },
      //   });

      //   map.addControl(controlBar);
      // });

      map.on("click", this.onMapClick);

      map.on("zoomend", this.debounceZoomChange);

      this.scene = new Scene({
        id: "map",
        logoVisible: false,
        map: new GaodeMapV2({
          mapInstance: map,
        }),
      });

      this.scene.on("loaded", () => {
        const zoom = new ExportImage({
          position: "leftbottom",
          title: "导出地图",
          onExport: (base64) => {
            // download(base64)

            saveAs(base64, "map.png");
          },
        });
        this.scene.addControl(zoom);
      });

      this.scene?.addImage("video", "/icon/monitor-active.png");
      this.scene?.addImage("liquidLevel", "/icon/liquid-active.png");
      this.scene?.addImage("flowMeter", "/icon/flow-active.png");
      this.scene?.addImage("cutoffwell", "/icon/intercepting-well-active.png");
      this.scene?.addImage("rainFall", "/icon/rain-active.png");
      this.scene?.addImage("water-quality", "/icon/water-quality-active.png");
      this.scene?.addImage("alert", "/icon/alert-active.png");

      this.scene?.addImage("selected", "/icon/selected.png");
    },

    rotatechange() {
      this.rotation = this.map.getRotation();
    },
    rotate(rotation) {
      if (this.map) {
        this.map.setRotation(rotation);
      }
    },

    onMapClick(e) {
      const obj = {
        lng: e.lnglat.getLng(),
        lat: e.lnglat.getLat(),
      };

      this.getDistance(obj);
      this.getArea(obj);
    },

    onSelect(list) {
      this.selected = list;
    },

    onZoomChange() {
      const zoom = this.map?.getZoom() || 3;
      // let size = [zoom, zoom];

      if (zoom >= 20) {
        this.sewagePipeLayer?.size(3);
        this.activeSewagePipeLayer?.size(3);
        this.rainpipeLayer?.size(3);
        this.activerainpipeLayer?.size(3);

        this.map.setPitch(20, true);
      } else {
        this.sewagePipeLayer?.size(1);
        this.activeSewagePipeLayer?.size(1);
        this.rainpipeLayer?.size(1);
        this.activerainpipeLayer?.size(1);

        this.map.setPitch(0, true);
      }

      this.liquidLayer?.size(zoom * 0.55);
      this.flowLayer?.size(zoom * 0.55);
      this.cutoLayer?.size(zoom * 0.55);
      this.monitorLayer?.size(zoom * 0.55);
      this.rainfallLayer?.size(zoom * 0.55);
      this.waterQualityLayer?.size(zoom * 0.55);
      this.alertLayer?.size(zoom * 0.55);
      this.facilityActiveLayer?.size(zoom * 0.6);
    },
  },
};
</script>
<style lang="less" scoped>
#map {
  position: fixed;
  height: 105vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 1;
}
</style>

<style>
.l7-popup {
  width: auto !important;
}

.alert-popup .header .title {
  font-weight: bold;
  margin-bottom: 1em;
  color: var(--theme-color);
}

.alert-popup .body {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.alert-popup .body .item {
  display: flex;
  align-items: center;
  color: #666;
  font-size: 12px;
  gap: 1em;
}
</style>