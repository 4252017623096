<template>
  <span>
    <span class="link" @click="visible = true">
      <span style="font-size: 1.4vh">更多</span>
      <a-icon type="right" style="font-size: 1.4vh" />
    </span>

    <a-modal
      title="告警列表"
      :visible="visible"
      width="1130px"
      :footer="null"
      @cancel="cancel"
    >
      <a-form-model
        layout="inline"
        @keyup.enter.native="query"
        style="margin-bottom: 2vh"
      >
        <a-form-model-item>
          <a-input
            placeholder="设备编号"
            style="width: 130px"
            v-model="form.code"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-input
            placeholder="设备名称"
            style="width: 130px"
            v-model="form.name"
          />
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="设备类型"
            v-model="form.type"
            style="width: 130px"
          >
            <a-select-option
              v-for="item in typeList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-select
            placeholder="告警分类"
            v-model="form.category"
            style="width: 130px"
          >
            <a-select-option
              v-for="item in alertCategoryList"
              :key="item.value"
              :value="item.value"
              >{{ item.name }}</a-select-option
            >
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-range-picker v-model="form.date" style="width: 320px" />
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button type="primary" @click="query">查询</a-button>
            <a-button @click="reset">重置</a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>

      <a-table
        bordered
        @change="onChange"
        :data-source="list"
        :loading="loading"
        rowKey="id"
        :pagination="{
          total,
          current,
          pageSize,
          showTotal: (total) => `共 ${total} 条记录`,
        }"
      >
        <a-table-column title="设备编号" data-index="code"></a-table-column>
        <a-table-column title="设备名称" data-index="name"></a-table-column>
        <a-table-column title="设备类型">
          <template slot-scope="text">
            <DataDictFinder dictType="deviceType" :dictValue="text.type" />
          </template>
        </a-table-column>

        <a-table-column title="告警内容">
          <template slot-scope="text">
            <div style="color: #f00">{{ text.remark }}</div>
          </template>
        </a-table-column>

        <a-table-column
          title="告警时间"
          data-index="alertTime"
          align="center"
        ></a-table-column>

        <a-table-column title="操作" align="center">
          <template slot-scope="text">
            <a href="#" @click.prevent="openLocation(text)">位置</a>
          </template>
        </a-table-column>
      </a-table>
    </a-modal>
  </span>
</template>

<script >
import { fetchAlertList } from "@/api/alert";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      visible: false,
      form: {},
      list: [],
      loading: false,
      total: 0,
      current: 1,
      pageSize: 10,
    };
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),

    typeList() {
      return this.findDataDict("deviceType");
    },

    alertCategoryList() {
      return this.findDataDict("alertCategory");
    },
  },
  watch: {
    visible() {
      if (this.visible) {
        if (this.list.length === 0) {
          this.query();
        }
      }
    },
  },

  methods: {
    getList() {
      this.loading = true;
      let { current, pageSize, form } = this;

      let startDate, endDate;
      if (Array.isArray(form.date) && form.date.length === 2) {
        startDate = form.date[0].format("YYYY-MM-DD");
        endDate = form.date[1].format("YYYY-MM-DD");
      }

      fetchAlertList({
        pageNum: current,
        pageSize,
        ...form,
        startDate,
        endDate,
        date: undefined,
      })
        .then((res) => {
          this.loading = false;
          if (Array.isArray(res.list)) {
            this.list = res.list;
            this.total = res.totalSize || 0;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    onChange(pagination) {
      this.current = pagination.current;
      this.pageSize = pagination.pageSize;
      this.getList();
    },
    reset() {
      this.form = {};
      this.current = 1;
      this.getList();
    },
    query() {
      this.current = 1;
      this.getList();
    },
    cancel() {
      this.visible = false;
    },

    openLocation(text) {
      this.cancel();
      this.$emit("select", {
        ...text,
      });
    },
  },
};
</script>

<style lang="less" scoped>
.link {
  font-weight: normal;
}
</style>