<template>
  <div>
    <div class="list">
      <div
        class="item"
        v-for="(item, index) in facilityStatusList_NotHaveRiverVersion"
        :key="index"
      >
        <div class="header">
          <img class="bg" src="@/assets/status-bg.png" alt="" />
          <div class="text">
            <img class="icon" :src="getType(item.type)" />
            <DataDictFinder dictType="deviceType" :dictValue="item.type" />
          </div>
        </div>
        <div class="body">
          <div class="statistic">
            <div class="label">正常</div>
            <div class="value">
              <span>{{ item.normal }}</span>
              <span>台</span>
            </div>
          </div>
          <div
            class="statistic"
            style="cursor: pointer"
            title="查看更多"
            @click="open(item)"
          >
            <div
              class="label"
              :style="{ color: item.notNormal > 0 ? '#ff0d0d' : 'inherit' }"
            >
              异常
            </div>
            <div class="value">
              <span
                :style="{ color: item.notNormal > 0 ? '#ff0d0d' : 'inherit' }"
                >{{ item.notNormal }}</span
              >
              <span>台</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    //...mapGetters("home", ["facilityStatusList"]),
    ...mapGetters("home", ["facilityStatusList_NotHaveRiverVersion"]),
  },

  data() {
    return {
      visible: false,
      detail: {},
    };
  },

  mounted() {
    this.getFacilityList();
  },
  methods: {
    ...mapActions("home", ["getFacilityList"]),
    getType(type) {
      const typeList = [
        {
          type: "video",
          url: "/icon/monitor-active.png",
        },
        {
          type: "waterquality",
          url: "/icon/water-quality-active.png",
        },
        {
          type: "liquidLevel",
          url: "/icon/liquid-active.png",
        },

        {
          type: "rainFall",
          url: "/icon/rain-active.png",
        },
        {
          type: "flowMeter",
          url: "/icon/flow-active.png",
        },
        {
          type: "cutoffWell",
          url: "/icon/intercepting-well-active.png",
        },
        {
          type: "pumpStation",
          url: "/icon/intercepting-well-active.png",
        },
      ];

      return typeList.find((item) => item.type === type)?.url || "";
    },

    open(item) {
      this.detail = item;
      this.visible = true;
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 2vh;
  column-gap: 1vw;

  .item {
    cursor: pointer;
    color: #fff;

    .header {
      position: relative;
      margin-bottom: 1vh;

      .bg {
        width: 100%;
        display: block;
      }

      .text {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        display: flex;
        align-items: center;
        gap: 0.4vw;
        font-size: 1.6vh;

        .icon {
          height: 100%;
        }
      }
    }

    .body {
      display: flex;

      .statistic {
        flex: 1;
      }

      .label {
        font-size: 1.2vh;
      }

      .value {
        span:first-child {
          font-size: 1.4em;
          padding-right: 0.2vw;
        }

        span:last-child {
          font-size: 1.2vh;
        }
      }
    }
  }

  .active {
    background-color: var(--theme-color);
    color: #fff;

    .header {
      color: #fff;

      &::before {
        content: "";
        position: absolute;
        top: 15%;
        bottom: 10%;
        left: -0.5vw;
        width: 3px;
        height: 75%;
        background-color: #000;
      }
    }

    .body {
      .label {
        color: #fff;
      }
    }
  }
}

.alert {
  // margin-top: 2vh;

  .swiper-slide {
    display: flex;
    gap: 1vw;
  }

  .item {
    flex: 1;
    background-color: rgba(103, 146, 127, 0.1);
    padding: 0.5vh;

    .inner {
      padding: 0.8vh 0.4vw;
      border: 1px solid var(--theme-color);
      opacity: 1;
      border-radius: 5px;
    }

    .value {
      font-size: 1.6vh;
      font-weight: 600;
      color: var(--theme-color);
    }

    .title {
      font-size: 1.4vh;
      font-weight: 600;
      color: var(--theme-color);
      margin-bottom: 1vh;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .extra {
        color: #c90202;
      }
    }

    .desc {
      font-size: 1.3vh;
      font-weight: 600;
      opacity: 0.75;
    }
  }
}
</style>