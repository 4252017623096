import { mapState, mapMutations } from "vuex";
import { Marker, PointLayer, PolygonLayer } from '@antv/l7';

export default {

    computed: {
        ...mapState('mapTools', ['searchVisible', 'searchLocation', 'isGettingDistance', 'isGettingArea', 'selectedLnglat',
            'max', 'min', 'up', 'down', 'left', 'right',
            'layer',
            'area'
        ])
    },

    watch: {
        searchVisible() {
            if (!this.searchVisible && this.searchLocation.lat) {
                this.fly();
            }
        },
        isGettingDistance() {
            if (this.isGettingDistance) {
                this.scene?.removeAllMakers();
            }
        },
        isGettingArea() {
            if (this.isGettingArea) {
                this.scene?.removeAllMakers();
            }
        },

        max() {
            this.map?.zoomIn();
        },
        min() {
            this.map?.zoomOut();
        },
        up() {
            this.map?.panBy(0, -20)
        },
        down() {
            this.map?.panBy(0, 20)
        },
        left() {
            this.map?.panBy(-20, 0)
        },
        right() {
            this.map?.panBy(20, 0)
        },

        layer() {
            if (!window.AMap) return;
            const { AMap } = window;
            if (this.layer === 'satellite') {
                if (!this.satelliteLayer) {
                    this.satelliteLayer = new AMap.TileLayer.Satellite();
                }
                this.map?.setLayers([this.satelliteLayer])
            } else {
                if (!this.defaultLayer) {
                    this.defaultLayer = new AMap.TileLayer({
                        zooms: [3, 20],    //可见级别
                        visible: true,    //是否可见
                        opacity: 1,       //透明度
                        zIndex: 1,        //叠加层级
                        mapStyle: "amap://styles/grey"
                    })
                }
                this.map?.setLayers([this.defaultLayer])
                this.map?.setMapStyle("amap://styles/grey")


            }
        },

        area() {
            this.areaLayer && this.scene?.removeLayer(this.areaLayer)

            if (this.area?.locations?.length > 0) {
                const list = this.area.locations.split(";").map((item) => {
                    const arr = item.split(",");
                    return arr.map(item => parseFloat(item))
                })

                this.areaLayer = new PolygonLayer({
                    zIndex: 1,

                })
                    .source(
                        {
                            "type": "FeatureCollection",
                            "features": [
                                {
                                    "type": "Feature", "id": "01", "properties": {}, "geometry": {
                                        "type": "Polygon",
                                        "coordinates": [
                                            list
                                        ]
                                    }
                                },
                            ]
                        }
                    )

                    .color(this.area.color)
                    .shape(this.area.method === 'fill' ? 'fill' : 'line')
                    .active(true);
                this.scene?.addLayer(this.areaLayer)
            }



        }
    },

    methods: {

        fly() {
            const { lng, lat } = this.searchLocation
            this.scene?.setCenter([lng, lat]);

            if (this.searchLocationLayer) {
                this.scene?.removeLayer(this.searchLocationLayer)
            }

            this.searchLocationLayer = new PointLayer({
                zIndex: 10,
            })
                .source([{
                    lng,
                    lat
                }], {
                    parser: {
                        type: 'json',
                        x: 'lng',
                        y: 'lat'
                    }
                })
                .shape('simple')
                .size(10)
                .color('#f00')

            this.scene?.addLayer(this.searchLocationLayer)

        },



        ...mapMutations('mapTools', ['setSelectedLnglat']),


        getDistance(lnglat) {

            if (this.isGettingDistance) {
                const marker = new Marker().setLnglat(lnglat)
                this.scene?.addMarker(marker)
                this.setSelectedLnglat([...this.selectedLnglat, lnglat])

            }
        },


        getArea(lnglat) {

            if (this.isGettingArea) {
                const marker = new Marker().setLnglat(lnglat)
                this.scene?.addMarker(marker)
                this.setSelectedLnglat([...this.selectedLnglat, lnglat])

            }
        },



    }

}