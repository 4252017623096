<template>
  <div>
    <div class="list">
      <div class="item content">
        <div class="body">
          <div class="statistic" v-if="list.length > 0">
            <div
              class="name"
              v-for="item in list.filter((item, index) => index < 1)"
              :key="item.id"
            >
              <img class="icon" src="@/assets/alert.png" alt="" />
              <span>{{ item.name }}</span>
              <span style="margin-left: auto">{{ item.remark }}</span>
            </div>
          </div>
          <div
            class="statistic"
            v-else
            style="text-align: center; padding: 1vh 0"
          >
            暂无数据
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fetchAlertList } from "@/api/alert";
export default {
  data() {
    return {
      list: [],
    };
  },

  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      fetchAlertList({
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        if (res && Array.isArray(res.list)) {
          this.list = Object.freeze(res.list);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.list {
  display: flex;
  align-items: flex-start;
  color: #fff;
  font-size: 0.6vw;

  .item {
    flex-shrink: 0;

    .body {
      display: flex;

      .value {
        span:first-child {
          padding-right: 0.2vw;
        }

        span:last-child {
        }
      }
    }
  }
}

.content {
  flex: 1;
}

.statistic {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1vh;
}

.icon {
  height: 1.4vh;
}

.name {
  display: flex;
  align-items: center;
  gap: 0.4vw;
}
</style>