<template>
  <div class="tool-bar">
    <span class="select-all-box">
      <img
        class="select-all"
        src="@/assets/home/selectAll-active.png"
        v-if="selected.length === tools.length"
        @click="onSelectAll"
      />
      <img
        class="select-all"
        src="@/assets/home/selectAll.png"
        v-else
        @click="onSelectAll"
      />
    </span>
    <div
      class="item"
      v-for="item in tools"
      :key="item.title"
      @click="onSelect(item)"
      :class="selected.indexOf(item.title) > -1 ? 'active' : ''"
    >
      <img :src="item.activeIcon" v-if="selected.indexOf(item.title) > -1" />
      <img :src="item.icon" v-else />
      <span>{{ item.title }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    selected: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tools: [
        {
          icon: "/icon/monitor.png",
          activeIcon: "/icon/monitor-active.png",
          title: "视频监控",
        },
        {
          icon: "/icon/flow.png",
          activeIcon: "/icon/flow-active.png",
          title: "流量计",
        },
        {
          icon: "/icon/liquid.png",
          activeIcon: "/icon/liquid-active.png",
          title: "液位计",
        },
        {
          icon: "/icon/rain.png",
          activeIcon: "/icon/rain-active.png",
          title: "雨量计",
        },
        {
          icon: "/icon/water-quality.png",
          activeIcon: "/icon/water-quality-active.png",
          title: "水质监测站",
        },
        {
          icon: "/icon/intercepting-well.png",
          activeIcon: "/icon/intercepting-well-active.png",
          title: "智慧截流井",
        },
        {
          icon: "/icon/sewage-pipe.png",
          activeIcon: "/icon/sewage-pipe-active.png",
          title: "污水管网",
        },
        {
          icon: "/icon/rain-pipe.png",
          activeIcon: "/icon/rain-pipe-active.png",
          title: "雨水管网",
        },
        {
          icon: "/icon/alert.png",
          activeIcon: "/icon/alert-active.png",
          title: "告警列表",
        },
      ],
    };
  },
  methods: {
    onSelect(item) {
      const index = this.selected.indexOf(item.title);
      if (index > -1) {
        this.selected.splice(index, 1);
      } else {
        this.selected.push(item.title);
      }
      this.$emit("change", this.selected);
    },
    onSelectAll() {
      if (this.selected.length !== this.tools.length) {
        this.$emit(
          "change",
          this.tools.map((item) => item.title)
        );
      } else {
        this.$emit("change", []);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.tool-bar {
  position: fixed;
  bottom: 2vh;
  left: 50vw;
  transform: translateX(-50%);

  background: linear-gradient(
    180deg,
    rgba(55, 164, 231, 0) 0%,
    rgba(87, 161, 227, 0.2) 100%
  );

  padding: 1vh 1vw;
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 12px;
  gap: 1vw;

  .select-all-box {
    position: relative;
    cursor: pointer;

    &::after {
      content: "";
      position: absolute;
      width: 2px;
      height: 4vh;
      left: 5vh;
      background: linear-gradient(
        180deg,
        rgba(103, 146, 127, 0) 0%,
        #67927f 53%,
        rgba(103, 146, 127, 0) 100%
      );
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }
  }

  .select-all {
    cursor: pointer;
    width: 4vh;
  }

  .item {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    text-align: center;
    cursor: pointer;

    img {
      width: 3vh;
      margin-bottom: 0.25em;
    }

    span {
      white-space: nowrap;
    }
  }

  .active {
    color: #fff;
  }
}
</style>
