import request from '@/api/request'

function fetchRiverDetail(data) {
    return request({
        url: '/model-analysis/pipe/area/locations',
        method: 'post',
        data
    })
}


function fetchRiverList(data) {
    return request({
        url: '/model-analysis/pipe/area',
        method: 'post',
        data
    })
}

export default {
    data() {
        return {
            riverPolygonList: [],
            riverDetail: {},
            riverVisible: false,
        };
    },

    methods: {
        getRiverList() {
            if (this.riverPolygonList.length === 0) {
                fetchRiverList({
                    category: "river",
                }).then((res) => {
                    console.log('river list', res)
                    if (Array.isArray(res)) {
                        this.addRiverText(res);
                        const riverList = res.map((item) => item.name);
                        this.getRiverLnglatData(riverList);
                    }
                });
            } else {
                this.riverPolygonList?.forEach((item) => {
                    item.show();
                });
            }
        },

        getRiverLnglatData(list) {
            fetchRiverDetail({
                category: "river",
                names: list,
                type: "area",
            }).then((res) => {
                console.log("river detail", res);
                if (Array.isArray(res)) {
                    this.renderRiverPolygon(res);
                }
            });
        },

        renderRiverPolygon(list) {
            const { AMap } = window;

            list.forEach((item) => {
                const path = item.locs.map((element) => {
                    return new AMap.LngLat(
                        parseFloat(element[0]),
                        parseFloat(element[1])
                    );
                });

                var polygon = new AMap.Polygon({
                    path,
                    fillColor: "#1890ff", // 多边形填充颜色
                    borderWeight: 2, // 线条宽度，默认为 1
                    strokeColor: "#1890ff", // 线条颜色
                    extData: {
                        name: item.name,
                        remark: item.remark,
                    },
                });

                polygon.on("click", this.onRiverPolygonClick);

                this.map?.add(polygon);
                this.riverPolygonList.push(polygon);
            });
        },

        // 渲染河道名称
        addRiverText(list) {
            const { AMap } = window;
            list.forEach(item => {
                var text = new AMap.Text({
                    text: item.name,
                    anchor: 'center', // 设置文本标记锚点
                    style: {
                        'padding': '2px 8px',
                        'border-radius': '4px',
                        'background-color': 'rgba(0,0,0,0.8)',
                        'border-width': 0,
                        'box-shadow': '0 2px 6px 0 rgba(114, 124, 245, .5)',
                        'text-align': 'center',
                        'font-size': '12px',
                        'color': '#fff'
                    },
                    position: [item.x, item.y]
                });

                text.setMap(this.map);
            })
        },

        onRiverPolygonClick(e) {
            this.riverDetail = e.target._opts.extData;
            this.riverVisible = true;
        },

        removeRiverList() {
            this.riverPolygonList?.forEach((item) => {
                item.hide();
            });
        },

        clearRiverList() {
            this.riverPolygonList?.forEach((item) => {
                item.off("click", this.onRiverPolygonClick);
                item.remove();
            });
        },
    },
};

