import request from '../request'

export function fetchAlertList(params = {}) {
    return request({
        url: '/model-analysis/alert/list',
        params: {
            ...params,
            processStatus: 'InAlarm',
        },
    })
}