<template>
  <div style="height: 14vh" id="pipe-diameter"></div>
</template>

<script>
import * as echarts from "echarts/core";
import {
  TooltipComponent,
  GridComponent,
  LegendComponent,
} from "echarts/components";
import { BarChart } from "echarts/charts";
import { CanvasRenderer } from "echarts/renderers";

echarts.use([
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
]);

export default {
  props: {
    sewageDiameter: {
      type: Object,
      default: () => ({}),
    },
    rainDiameter: {
      type: Object,
      default: () => ({}),
    },
  },

  beforeDestroy() {
    this.chart?.dispose();
  },

  watch: {
    sewageDiameter: {
      handler() {
        this.initChart();
      },
      deep: true,
    },
  },

  methods: {
    initChart() {
      if (this.chart) return;

      var chartDom = document.getElementById("pipe-diameter");
      this.chart = echarts.init(chartDom);

      console.log("data", [
        this.sewageDiameter.dn400Length,
        this.rainDiameter.dn400Length,
      ]);

      const option = {
        color: ["#2793E1", "#01C8E3", "#FFA700"],
        legend: { textStyle: { color: "#fff" }, show: true },

        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
          },
        },

        grid: {
          top: "20%",
          right: "5%",
          bottom: "15%",
          left: "12%",
        },

        xAxis: {
          type: "value",
          axisLabel: { color: "#fff" },
          // boundaryGap: [0, 0.01]
        },
        yAxis: {
          type: "category",
          data: ["污水", "雨水"],
          axisLabel: { color: "#fff" },
        },

        // xAxis: [
        //   {
        //     type: "category",
        //     // boundaryGap: false,
        //     data: ["污水", "雨水"],
        //   },
        // ],
        // yAxis: [
        //   {
        //     position: "top",
        //     type: "value",
        //     // inverse: true,
        //     axisLine: {
        //       show: true,
        //     },
        //   },
        // ],
        series: [
          {
            name: "DN400以下",
            type: "bar",
            stack: "value",
            data: [
              this.sewageDiameter.dn400Length,
              this.rainDiameter.dn400Length,
            ].filter((item) => item),
          },
          {
            name: "DN400-700",
            type: "bar",
            stack: "value",

            data: [
              this.sewageDiameter.dn400ToDN700Length,
              this.rainDiameter.dn400ToDN700Length,
            ].filter((item) => item),
          },
          {
            name: "DN700以上",
            type: "bar",
            stack: "value",

            data: [
              this.sewageDiameter.dn700Length,
              this.rainDiameter.dn700Length,
            ].filter((item) => item),
          },
        ],
      };

      option && this.chart.setOption(option);
    },
  },
};
</script>

<style lang="less" scoped>
</style>